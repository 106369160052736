<template>
  <div class="sidebar">
    <el-menu :default-active="onRoute" class="sidebar-el-menu" :collapse="collapse" :background-color="subjectColor"
             text-color="#17191A" active-text-color="#51CBCD" router unique-opened>
      <template v-for="item in organizationList">
        <template v-if="item.childs">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title" class="title">{{ item.title }} </span>
            </template>
            <template v-for="subItem in item.childs">
              <template>
                <!-- <el-submenu :index="subItem.index" :key="subItem.index"
                  class="title">
                  <template slot="title" class="title">{{subItem.title}}
                  </template>
                  <el-menu-item v-for="(threeItem, i) in subItem.childs" :key="i"
                    :index="threeItem.index" class="title">{{ threeItem.title }}
                  </el-menu-item>
                </el-submenu> -->
                <el-menu-item :index="subItem.index"
                              :key="subItem.index" class="title pl50">{{ subItem.title }}
                </el-menu-item>
              </template>
              <!--
                            <template >
                              <el-submenu  :index="subItem.index"
                                :key="subItem.index" class="title pl10">
                                <template slot="title" class="title">{{subItem.title}}
                                </template>
                                <el-menu-item v-for="(threeItem, i) in subItem.childs" :key="i"
                                  :index="threeItem.index" class="title ">{{ threeItem.title }}
                                </el-menu-item>
                              </el-submenu>
                              <el-menu-item v-else :index="subItem.index" :key="subItem.index" class="title pl50"
                                :class="!subItem.memberRoot ? 'memberRoot' : '' ">{{ subItem.title }}
                              </el-menu-item>
                            </template> -->
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index" class="title">
            <span slot="title" class="title" style="margin-left: 5px;">
							{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from "../../service/bus";
import {
  mapState
} from 'vuex';

export default {
  name: "commonSidebar",
  data() {
    return {
      isFlag: false,
      collapse: false,
      isMain: false,
      member: false,
      info: '',
      if_flag: null,
      organizationList: []
    };
  },
  created() {
    // 控制折叠面板
    bus.$on("collapse", (msg) => {
      console.log(msg)
      this.collapse = msg;
      bus.$emit("collapse-content", msg);
    });
  },
  mounted() {
    this.getNavList()
  },
  computed: {
    ...mapState(['routerArr']),
    // 主题颜色
    subjectColor() {
      return this.$store.state.subjectColor;
    },
    // 路由配置
    onRoute() {
      return this.$route.path.replace("/", "");
    },
  },
  watch: {
    routerArr(val) {
      try {
        let than = this
        this.items.map(item => {
          if (item.id || item.type == 'customer') { //父级
            if (val.includes(item.id)) {
              item.member = true
            } else if (item.type == 'customer') {
              item.member = true
            } else {
              item.member = false
            }

            if (item.subs) {
              item.subs.map(subItem => {
                if (subItem.id) { //子集

                  if (val.includes(subItem.id)) {
                    if (subItem.id == '022') {
                      than.items[2].member = true
                      console.log(than.items, 'items')
                    }
                    subItem.memberRoot = true
                    if (subItem.administrator == false) {
                      subItem.root = false
                      subItem.administrator = true
                    }
                  } else {
                    subItem.memberRoot = false
                  }
                }

              })
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  },
  methods: {
    async getNavList() {
      let type = localStorage.getItem("type")
      if (type == 1) {
        this.organizationList = [
          {
            icon: "#icon-dashboard",
            index: "dashboard",
            title: "工作台",
            member: true
          },
          {
            index: "accountManagement",
            title: "账户管理",
            childs: [
              {
                index: "passwordManagement",
                title: "密码管理",
              },
              {
                index: "bankManagement",
                title: "银行卡",
              },
              {
                index: "googleValidator",
                title: "绑定谷歌",
              },
            ]
          },
          {
            index: "caiwuManagement",
            title: "财务管理",
            childs: [
              {
                index: "epositApplication",
                title: "入金申请",
              },
              {
                index: "manualPayment",
                title: "手动代付",
              },
              // {
              //   index: "checkstand",
              //   title: "收银台",
              // },
              {
                index: "transactionStatistics",
                title: "交易统计",
              },
              {
                index: "accountChangeRecord",
                title: "账变记录",
              },
            ]
          },
          {
            index: "orderManagement",
            title: "订单管理",
            childs: [
              // {
              //   index: "daishouOrder",
              //   title: "代收订单",
              // },
              {
                index: "daifuOrder",
                title: "代付订单",
              }
            ]
          },
          {
            index: "agentManagement",
            title: "代理管理",
            childs: [
              {
                index: "shlist",
                title: "商户列表",
              },
              {
                index: "agentRecord",
                title: "交易记录",
              },
              {
                index: "setFl",
                title: "设置费率",
              },
            ]
          },
          //      {
          //        index: "jiesuanManagement",
          //        title: "结算管理",
          //        childs: [
          //          {
          //            index: "settlementApplication",
          //            title: "结算申请",
          //          },
          // {
          //   index: "settlementRecord",
          //   title: "结算记录",
          // }
          //        ]
          //      },
          {
            index: "apiManagement",
            title: "API管理",
            childs: [
              {
                index: "channel",
                title: "代收通道",
              },
              {
                index: "rate",
                title: "代付费率",
              },
              {
                index: "message",
                title: "API信息",
              }
            ]
          },
        ]
      } else {
        this.organizationList = [
          {
            icon: "#icon-dashboard",
            index: "dashboard",
            title: "工作台",
            member: true
          },
          {
            index: "accountManagement",
            title: "账户管理",
            childs: [
              {
                index: "passwordManagement",
                title: "密码管理",
              },
              {
                index: "bankManagement",
                title: "银行卡",
              },
              {
                index: "googleValidator",
                title: "绑定谷歌",
              },
            ]
          },
          {
            index: "caiwuManagement",
            title: "财务管理",
            childs: [
              {
                index: "epositApplication",
                title: "入金申请",
              },
              {
                index: "manualPayment",
                title: "手动代付",
              },
              // {
              //   index: "checkstand",
              //   title: "收银台",
              // },
              {
                index: "transactionStatistics",
                title: "交易统计",
              },
              {
                index: "accountChangeRecord",
                title: "账变记录",
              },
            ]
          },
          {
            index: "orderManagement",
            title: "订单管理",
            childs: [
              // {
              //   index: "daishouOrder",
              //   title: "代收订单",
              // },
              {
                index: "daifuOrder",
                title: "代付订单",
              }
            ]
          },
          //      {
          //        index: "jiesuanManagement",
          //        title: "结算管理",
          //        childs: [
          //          {
          //            index: "settlementApplication",
          //            title: "结算申请",
          //          },
          // {
          //   index: "settlementRecord",
          //   title: "结算记录",
          // }
          //        ]
          //      },
          {
            index: "apiManagement",
            title: "API管理",
            childs: [
              {
                index: "channel",
                title: "代收通道",
              },
              {
                index: "rate",
                title: "代付费率",
              },
              {
                index: "message",
                title: "API信息",
              }
            ]
          },
        ]
      }
    },
  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  width: 208px;
  left: 0;
  top: 68px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 208px;
}

.sidebar > ul {
  height: 100%;
}

.sidebar .el-menu {
  border-right: none;
}

.title {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* .el-menu-item {
border-left: #fff solid 6px;
} */
/* 设置鼠标悬停时el-menu-item的样式 */
/* .el-menu-item:hover {
border-left: #6190e8 solid 6px !important;
background-color: #e2eff9 !important;
color: #6190e8 !important;
} */

/* .el-menu-item:hover i {
color: #6190e8;
} */

/* 设置选中el-menu-item时的样式 */
.el-menu-item,
.el-submenu {
  /* opacity: 0.8; */
}

.el-menu-item .is-active {
  opacity: 1;
}

.memberRoot {
  display: none;
}

.el-submenu__title i {
  color: #000000;
  margin-right: 5px;
}
</style>
